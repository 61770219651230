
import { defineComponent, ref } from "vue";
export default defineComponent({
  name: "customList",
  setup() {
    const records = ref([{
        id: 1,
        name: 'abc',
        industryType: 'customer goods',
        pan: 'AAC998SCS',
        varified: true,
        active: false  
      },
      {
        id: 2,
        name: 'abc',
        industryType: 'customer goods',
        pan: 'AAC998SCS',
        varified: true,
        active: false  
      },
      {
        id: 3,
        name: 'abc',
        industryType: 'customer goods',
        pan: 'AAC998SCS',
        varified: true,
        active: false  
      },
      {
        id: 4,
        name: 'abc',
        industryType: 'customer goods',
        pan: 'AAC998SCS',
        varified: true,
        active: false  
      },
      {
        id: 5,
        name: 'abc',
        industryType: 'customer goods',
        pan: 'AAC998SCS',
        varified: true,
        active: false  
      }]);
    return {
      records,
    };
  },
});

